import './App.css';
import Home from "./pages/Home";

const App = () =>
{
    return (
        <div className="App">
            <Home />
        </div>
    );
}

export default App;
